/** @format */

import { Link } from "gatsby";
import React, { useEffect, useState, useContext } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { AppContext } from "../../utils/ContextWrapper";

function Recetas() {
  const { store, actions } = useContext(AppContext);

  const { tipoRecetaSeleccionada } = store;
  const { setTipoRecetaSeleccionada } = actions;

  const [recetas, setRecetas] = useState([]);

  const [tiposRecetas] = useState([
    { descripcion: "Selecciona un tipo de receta", idTipoReceta: 0 },
    { descripcion: "Desayunos", idTipoReceta: 1 },
    { descripcion: "Almuerzos", idTipoReceta: 2 },
    { descripcion: "Snacks", idTipoReceta: 3 },
    { descripcion: "Cenas", idTipoReceta: 4 },
  ]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handeChangeTipoReceta = (ev) => {
    setTipoRecetaSeleccionada(tiposRecetas[ev]);
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    let urlEnvio = "";
    if (tipoRecetaSeleccionada.idTipoReceta !== 0) urlEnvio = `/${tipoRecetaSeleccionada.idTipoReceta}`;
    fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/recetas${urlEnvio}`)
      .then((result) => result.json())
      .then((result) => {
        setLoading(false);
        setRecetas(result);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    /*fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/tiposRecetas`)
      .then((result) => result.json())
      .then((result) => {
        setTiposRecetas(result);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });*/
  }, [tipoRecetaSeleccionada]);

  return (
    <>
      <div>
        <DropdownButton
          id="dropdown-basic-button"
          onSelect={handeChangeTipoReceta}
          variant="warning"
          title={tipoRecetaSeleccionada.descripcion}
        >
          <Dropdown.Item eventKey="1">Desayunos</Dropdown.Item>
          <Dropdown.Item eventKey="2">Almuerzos</Dropdown.Item>
          <Dropdown.Item eventKey="3">Snacks</Dropdown.Item>
          <Dropdown.Item eventKey="4">Cenas</Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="recetas-container">
        {loading && <div>Espera un momento, estamos obteniendo la informacion de los recetas...</div>}
        {error && (
          <div>
            <br />
            <br />
            <br />
            <br />
            Ups!! Ocurrio un error al obtener los recetas, intenta recargar la página nuevamente.
            <br /> Si el problema persiste contacte al administrador del sitio.
          </div>
        )}
        {recetas.map((receta) => {
          return (
            <Link
              className="recetas-componente"
              key={receta.idReceta}
              to={`/recetas/detalle-receta/${receta.idReceta}`}
            >
              <img src={`${process.env.API_URL_CHAPIN}` + receta.url} alt={receta.nombreReceta} />
              <div className="recetas-componente-nombre subtitulo">{receta.nombreReceta}</div>
            </Link>
          );
        })}
      </div>
    </>
  );
}

export default Recetas;
